// Here you can add other styles

.login-bg-image {
  background-image: url("../assets/brand/Flocks\ Logo.svg");
  color: white;
  background-color: #163167;
  background-size: 350px;
  background-repeat: no-repeat;
  background-position: center;
}

@media (max-width: 992px) {
  .login-bg-image {
    background-size: 200px;
  }
}

@media (max-width: 360px) {
  .fixed-width {
    width: 360px !important;
    max-width: 360px !important;
    min-width: 360px !important;
  }
}

.table .table-primary-base {
  background-color: #163167;
  color: #ffffff;
}
.custom-card-color {
  background-color: #39d790 !important;
  color: #ffffff;
}
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.page-number {
  margin: 0 0px;
  cursor: pointer;
}

.page-number.active {
  font-weight: bold;
}

.main-div {
  width: 100%;
  max-width: 100%;
}

@media (max-width: 360px) {
  .main-div {
    max-width: 360px;
    margin: 0 auto;
  }
}

.forgot-password {
  width: 100%;
  margin-top: -23px !important;
  display: flex;
  justify-content: end;
}

.forgot-password-text {
  font-size: 16px;
}

@media (max-width: 990px) {
  .forgot-password-text {
    font-size: 12px;
    margin-top: 11px;
  }
}

@media (max-width: 800px) {
  .forgot-password-text {
    font-size: 16px;
  }
}

.btn-custom {
  width: 100px;
}

.btn-edit {
  border: none !important;
}

.otpInput {
  color: black !important;
  width: 100% !important;
  height: 50px !important;
}

.error-message {
  font-size: 13px;
  margin-top: -14px;
}

.error-message-otp {
  font-size: 13px;
  margin-top: 3px;
}

.btn-resend {
  background: transparent !important;
  color: gray;
  font-weight: 500;
  border: none;
  text-underline-offset: 1px;
  text-decoration: underline;
}

.btn-resend:hover {
  background: transparent !important;
  color: gray;
}

.resend-btn-div {
  display: flex;
  align-items: center;
  margin-top: 12px;
}

.code-text {
  margin-bottom: 0px !important;
}

@media (max-width: 540px) {
  .code-text {
    font-size: 12px;
  }
}

.btn-resend:disabled {
  background: transparent !important;
  color: gray;
}

@media (max-width: 360px) {
  .btn-resend {
    font-size: 12px;
  }
}

.error-login {
  font-size: 13px;
  margin-top: -8px;
}

.error-login-pass {
  font-size: 13px;
  margin-top: -24px;
}

@media (max-width: 360px) {
  .fixed-width-container {
    width: 360px !important;
    max-width: 360px !important;
    min-width: 360px !important;
  }
}

@media (max-width: 360px) {
  .fixed-width-table {
    width: 300px !important;
    max-width: 300px !important;
    min-width: 300px !important;
  }
}

@media (max-width: 768px) {
  .page-number:nth-child(n + 5):not(:last-child) {
    display: none;
  }
}

.pagination {
  background-color: transparent !important;
}

.pagination-div {
  display: flex !important;
  justify-content: end !important;
  align-items: center !important;
  margin-top: 10px !important;
}

.loader {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  width: 100% !important;
  height: 30vh;
}

.loader-parent {
  width: 100% !important;
}

.btn-pagination {
  cursor: pointer !important;
}

.btn-pagination-not {
  cursor: not-allowed !important;
}

.truncate {
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.hello {
  background-color: red !important;
}

.img-company {
  border-radius: 50% !important;
}

.btn-img {
  border-radius: 70% !important;
  position: absolute !important;
  left: 69px !important;
  top: 66px !important;
}

.img-div {
  position: relative !important;
}

.edit-btn-div {
  margin-top: -2px !important;
}

.btn-null {
  width: 100% !important;
  border: none !important;
}

.btn-null:active:focus-visible {
  box-shadow: none !important;
}

.loader-alignment {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  height: 80vh !important;
}

.loader-class {
  width: 20px !important;
  height: 20px !important;
}

.loadingBtn {
  width: 80px !important;
}

.custom-select {
  position: relative;
  width: 150px;
  font-family: "Arial, sans-serif";
}

.select-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px;
  border-radius: 5px;
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  cursor: pointer;
}

.selected {
  flex-grow: 1;
  font-family: "Roboto", sans-serif;
}

.arrow {
  transition: transform 0.3s;
}

.arrow.open {
  transform: rotate(180deg);
}

.options {
  position: absolute;
  width: 100%;
  font-family: "Roboto", sans-serif;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  border-top: none;
  z-index: 99;
}

.option {
  padding: 10px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.option:hover {
  background-color: #f1f1f1;
}

.select-div {
  display: flex !important;
  justify-content: end !important;
  align-items: center !important;
  margin-bottom: 15px !important;
}

.table-container {
  max-height: 400px;
  overflow-y: auto;
  position: relative;
}

.table-header {
  position: sticky;
  top: 0;
  z-index: 1;
}

.logo-img {
  height: 30px;
  width: 30px;
  object-fit: cover !important;
}

.logo-img {
  height: 35px;
  width: 35px;
  border-radius: 20px;
}
